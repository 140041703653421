<div [ngStyle]="{ margin: '0 15px 10px 15px' }">
  <h5>{{ title }}</h5>
  <span class="input-label">
    {{ label }}
  </span>
  <ng-container [ngSwitch]="type">
    <ion-select
      *ngSwitchCase="'select'"
      interface="popover"
      [(ngModel)]="value"
      class="select-outline"
      [ngStyle]="{ 'margin-bottom': '10px', 'margin-top': '5px' }"
      placeholder="Select an option"
    >
      <ion-select-option *ngFor="let opt of getOptions()" [value]="opt.key">
        {{ opt.label }}
      </ion-select-option>
    </ion-select>
    <ion-input
      *ngSwitchCase="'text'"
      [(ngModel)]="value"
      class="input-field"
      (keyup.enter)="onApply()"
      #filterInput
    ></ion-input>
  </ng-container>
  <div class="flex-space-between">
    <ion-button fill="clear" (click)="onBack()">
      <ion-icon class="icon-padded" name="arrow-back-outline"></ion-icon>
      Back
    </ion-button>
    <ion-button (click)="onApply()" [ngStyle]="{ width: '100px' }">Apply</ion-button>
  </div>
</div>
