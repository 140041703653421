import { Component, ViewChild } from '@angular/core';

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss'],
})
export class FilterDropdownComponent {
  @ViewChild('filterInput', { static: false }) filterInput;
  title: string = 'Filter';
  label: string;
  type: 'select' | 'text';
  options?: (string | { label: string; key: string })[];
  value?: string;
  onApplyCallback: (value: string) => {};
  onBackCallback: () => {};

  ionViewDidEnter() {
    setTimeout(() => {
      if (this.filterInput) {
        this.filterInput.setFocus();
      }
    }, 150);
  }

  onApply() {
    this.onApplyCallback(this.value);
  }

  onBack() {
    this.onBackCallback();
  }

  getOptions() {
    return this.options.map((o) => {
      if (typeof o === 'string') {
        return {
          label: o,
          key: o,
        };
      }

      return o;
    });
  }
}
